import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from '../pages/protected/DashboardLayout';
import PublicPages from '../pages/public/PublicPages';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// config
import { PATH_DASHBOARD, PATH_AUTH } from './paths';
import { Loader } from '../components/Loader';


export const PATH_AFTER_LOGIN = PATH_DASHBOARD.streams;
export const PATH_INIT = PATH_AUTH.login;


// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      element: (
        <GuestGuard>
          <PublicPages />
        </GuestGuard>
      ),
      children: [
        { element: <Navigate to={PATH_INIT} replace />, index: true },
        { path: 'login', element: <Login /> },
        { path: 'registration', element: <Registration /> },
        { path: 'forgot-password', element: <ForgotPass /> },
        { path: 'confirm-forgot-password', element: <ConfirmPasswordByEmail /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'account',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'streams', element: <Stream /> },
        { path: 'archive-stream/:id', element: <ArchivedVideos /> },
        {
          path: 'payment',
          children: [
            { element: <Payment />, index: true },
            { path: 'successful', element: <PaymentSuccessful /> },
          ]
        },
        { path: 'settings', element: <Settings /> },
      ],
    },

    // Error Routes
    {
      path: 'error',
      element: (
        <ErrorLayout />
      ),
      children: [
        { element: <Navigate to={'404'} replace />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '500', element: <Page500 /> },
      ],
    },

    { path: '/', element: <Navigate to="/auth" replace /> },
    { path: '*', element: <Navigate to="/error/404" replace /> },
  ]);
}


const Login = Loadable(lazy(() => import('../pages/public/login/Login')));
const Registration = Loadable(lazy(() => import('../pages/public/registration/Registration')));
const ForgotPass = Loadable(lazy(() => import('../pages/public/fogotPassword/ForgotPass')));
const ConfirmPasswordByEmail = Loadable(lazy(() => import('../pages/public/fogotPassword/ConfirmPasswordByEmail')));
const ArchivedVideos = Loadable(lazy(() => import('../pages/protected/archivedVideos/ArchivedVideos')));
const Stream = Loadable(lazy(() => import('../pages/protected/stream/Stream')));
const Payment = Loadable(lazy(() => import('../pages/protected/payment/Payment')));
const PaymentSuccessful = Loadable(lazy(() => import('../pages/protected/payment/PaymentSuccessful')));
const Settings = Loadable(lazy(() => import('../pages/protected/settings/Settings')));
const Page404 = Loadable(lazy(() => import('../pages/errors/Page404')));
const Page403 = Loadable(lazy(() => import('../pages/errors/Page403')));
const Page500 = Loadable(lazy(() => import('../pages/errors/Page500')));
const ErrorLayout = Loadable(lazy(() => import('../pages/errors/ErrorLayout')));