import axios, { AxiosResponse } from 'axios'

import API from './api'
import config from './config'

import ApiResponse from './ApiResponse';

export interface UserCredentials {
  email: string;
  password: string;
}

export interface RegisterUserCredentials {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

export interface UsersProfile {
  id: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  status: string;
  email: string;
  roles: string;
  customer: {
    aggregatedBalance: {
      hoursString: string;
      seconds: number;
    }
    id: string;
    paymentAccountId: string;
  }
}


export async function signIn(
  userCredentials: UserCredentials
): Promise<ApiResponse<LoginResponse>> {
  try {
    const response: AxiosResponse<LoginResponse> = await axios.post(`${config.urlApiServer}/auth/login`, userCredentials);

    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
      error: {
        code: 'error',
        message: error?.response?.data?.error?.properties[0]?.messages[0] || error.message,
        name: 'Authorization',
        errorName: error?.response?.data?.error?.properties[0]?.property,
      },
    };
  }
}

export async function signUp(
  userCredentials: RegisterUserCredentials
): Promise<ApiResponse<UsersProfile>> {
  try {
    const registeredUser: AxiosResponse<UsersProfile> = await axios.post(`${config.urlApiServer}/auth/register`, userCredentials);

    return {
      success: true,
      data: registeredUser.data,
      error: null,
    };
  } catch (error) {
    console.error(error);

    return {
      success: false,
      data: null,
      error: {
        code: 'error',
        message: error?.response?.data?.error?.properties[0]?.messages[0] || error.message,
        name: 'Registration',
        errorName: error?.response?.data?.error?.properties[0]?.property,
      },
    };
  }
}

export async function confirmSignUp(
  email: string,
  code: string
): Promise<ApiResponse<null>> {
  try {
    await axios.post(`${config.urlApiServer}/auth/register/confirm`, { email, code });

    return {
      success: true,
      data: null,
      error: null,
    };
  } catch (error) {
    console.error(error);

    return {
      success: false,
      data: null,
      error: {
        code: 'error',
        message: 'Invalid validation code',
        name: 'Registration',
      },
    };
  }
}

export async function signOut() {
  try {
    const token = localStorage.getItem('refresh_token')

    await API.post('/auth/logout', { refreshToken: token });
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

export async function forgotPassword(
  email: string
): Promise<ApiResponse<null>> {
  try {
    await axios.post(`${config.urlApiServer}/auth/password/reset`, { email });

    return {
      success: true,
      data: null,
      error: null,
    };
  } catch (error) {
    console.error(error);

    return {
      success: false,
      data: null,
      error: {
        code: 'error',
        message: error.response.data.error.properties[0].messages,
        name: 'Forgot password',
      },
    };
  }
}

export async function forgotPasswordSubmit(
  code: string,
  password: string,
): Promise<ApiResponse<null>> {
  try {
    await axios.post(`${config.urlApiServer}/auth/password/reset/confirm`, { code, password });
    return {
      success: true,
      data: null,
      error: null,
    };
  } catch (error) {
    console.error(error);

    return {
      success: false,
      data: null,
      error: {
        code: 'error',
        message: error?.response?.data?.error || error.message,
        name: 'Forgot password',
      },
    };
  }
}

export async function checkAuth(refreshToken: string): Promise<ApiResponse<LoginResponse>> {
  try {
    const response: AxiosResponse<LoginResponse> = await axios.post(
      `${config.urlApiServer}/auth/refresh-access-token`,
      { refreshToken }
    );

    return {
      success: true,
      data: response.data,
      error: null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
      error: {
        code: 'error',
        message: error.response.data.error.properties[0].messages[0],
        name: 'Authorization',
      },
    };
  }
}


export async function resendConfirmation(
  email: string
): Promise<ApiResponse<null>> {
  try {
    await axios.post(`${config.urlApiServer}/auth/register/resend-confirmation`, { email });

    return {
      success: true,
      data: null,
      error: null,
    };
  } catch (error) {
    console.error(error);

    return {
      success: false,
      data: null,
      error: {
        code: 'error',
        message: error.response.data.error.properties[0].messages,
        name: 'Registration',
      },
    };
  }
}